/*------------------------------------------------
// File Name:PDM_BodyCheck_DaoRu.js
// File Description:PDM_BodyCheck_DaoRu API方法js接口
// Author:zxl
// Create Time:2023/05/12 18:28:47
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckDaoru: "/PDM_BodyCheck_DaoRu"
}

export default api

//-------------------上传Excel-------------------
export function UploadExcel(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/UploadExcel",
        method: 'post',
        data: parameter
    })
}

//-------------------创建对象-------------------
export function CreatePDM_BodyCheck_DaoRuEntity(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/CreatePDM_BodyCheck_DaoRuEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_BodyCheck_DaoRuList(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/GetPDM_BodyCheck_DaoRuList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_BodyCheck_DaoRuListByPage(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/GetPDM_BodyCheck_DaoRuListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_BodyCheck_DaoRuEntity(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/GetPDM_BodyCheck_DaoRuEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_BodyCheck_DaoRuEntity(parameter) {
    return axios({
        url: api._bodycheckDaoru+ "/UpdatePDM_BodyCheck_DaoRuEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_BodyCheck_DaoRuEntity(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/DeletePDM_BodyCheck_DaoRuEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------逻辑删除---------------
export function DelPDM_BodyCheck_DaoRu(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/DelPDM_BodyCheck_DaoRu",
        method: 'post',
        data: parameter
    })
}

//-------------------获取导入的套餐分组集合---------------
export function GetDaoRuTaoCanList(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/GetDaoRuTaoCanList",
        method: 'post',
        data: parameter
    })
}

//-------------------根据套餐逻辑删除集合---------------
export function DelDaoRuTaoCan(parameter) {
    return axios({
        url: api._bodycheckDaoru + "/DelDaoRuTaoCan",
        method: 'post',
        data: parameter
    })
}
