<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          outlined
          style="width: 140px;"
          dense
          v-model="FilterString"
          label="患者名称"
          color="primary"
        />
        <q-input
          outlined
          style="width: 200px;"
          dense
          v-model="CertNo"
          label="证件号码"
          color="primary"
        />
        <q-input
          outlined
          style="width: 230px;"
          dense
          v-model="TaoCan_Name"
          label="套餐名称"
          color="primary"
        />
        <q-select
          map-options
          emit-value
          outlined
          dense
          style="width:120px;"
          v-model="YuYueState"
          color="primary"
          label="预约状态"
          :options="[
            { label: '全部', value: -1 },
            { label: '未约', value: 0 },
            { label: '已约', value: 1 },
          ]"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmBodycheckDaoruListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmBodycheckDaoruListLoading" @click="onExportData">导出</q-btn> -->
        <q-select
          map-options
          emit-value
          outlined
          dense
          style="width:200px;"
          v-model="TaoCanCode"
          color="primary"
          label="选择要删除的套餐"
          :options="DaoRuTaoCan"
          option-label="TaoCan_Name"
          option-value="TaoCan_Code"
        />
        <q-btn
          color="primary"
          style="height: 38px"
          :loading="PdmBodycheckDaoruListLoading"
          @click="onDelTaoCan()"
          >套餐删除</q-btn
        >
        <q-btn
          color="primary"
          style="height: 38px"
          :loading="PdmBodycheckDaoruListLoading"
          @click="onDel(1)"
          >删除体检</q-btn
        >
        <q-btn
          color="primary"
          style="height: 38px"
          :loading="PdmBodycheckDaoruListLoading"
          @click="onDel(2)"
          >删除疫苗</q-btn
        >
        <q-btn
          color="primary"
          style="height: 38px"
          :loading="PdmBodycheckDaoruListLoading"
          @click="onImportExcel"
          >导入Excel</q-btn
        >
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 140"
        :loading="PdmBodycheckDaoruListLoading"
        highlight-current-row
        size="mini"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <vxe-button
            flat
            color="primary"
            icon="fas  fa-edit"
            @click="onEdit(row)"
            >编辑</vxe-button
          >
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          >
        </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>
  </q-card>
  <q-uploader
    label="导入Excel"
    ref="ImportExcel"
    v-show="false"
    auto-upload
    :url="ApiUrl + 'api/PDM_BodyCheck_DaoRu/UploadExcel'"
    multiple
    @uploaded="onUploaded"
    @uploading="onUploading"
    :headers="[{ name: 'HospID', value: HospID }]"
  />

  <q-dialog v-model="pdmBodycheckDaorushowDlg" persistent>
    <BodycheckDaoruEdit @onLoadData="onLoadData" />
  </q-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import * as BodycheckDaoru from "@/api/pdm/bodycheckDaoru";
import dlg from "@/config/dialog";
import BodycheckDaoruEdit from "./bodycheckDaoruEdit.vue";
import * as DaoRu from "@/api/pdm/bodycheckDaoru";
export default {
  components: {
    BodycheckDaoruEdit,
  },
  computed: {
    ...mapState("PdmBodycheckDaoru", [
      "PdmBodycheckDaoruList",
      "PdmBodycheckDaoruListLoading",
      "PdmBodycheckDaoruEntity",
      "PdmBodycheckDaoruShowDlg",
    ]),

    pdmBodycheckDaorushowDlg: {
      get() {
        return this.PdmBodycheckDaoruShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit(
          "PdmBodycheckDaoru/setPdmBodycheckDaoruShowDlg",
          val
        );
      },
    },
  },
  data() {
    return {
      YuYueState: -1,
      ApiUrl: null,
      HospID: null,
      FilterString: "",
      TaoCan_Name: null,
      CertNo: null,
      DaoRuTaoCan: null,
      TaoCanCode:null,
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "Name",
            title: "姓名",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "SexName",
            title: "性别",
            sortable: true,
            align: "center",
            width: 80,
          },
          {
            field: "Age",
            title: "年龄",
            sortable: true,
            align: "left",
            width: 80,
          },
          {
            field: "CertNo",
            title: "证件号码",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            field: "Phone",
            title: "电话号码",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "XueYuan",
            title: "学院",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "TypesStr",
            title: "套餐分类",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "TaoCan_Code",
            title: "套餐编码",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "TaoCan_Name",
            title: "套餐名称",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "AddDate",
            title: "添加时间",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            title: "操作",
            width: 200,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmBodycheckDaoruList,
        data: null,
      },
    };
  },
  mounted() {
    this.ApiUrl = this.$store.getters["appUser/ImagesUrl"];
    //this.ApiUrl = "http://192.168.1.188:8088/";
    this.HospID = this.$store.getters["appUser/hospID"];
    this.onLoadData();
   
  },
  methods: {
    ...mapActions("PdmBodycheckDaoru", [
      "actGetPdmBodycheckDaoruListByPage",
      "actCreatePdmBodycheckDaoru",
      "actEditPdmBodycheckDaoruData",
      "actEditPdmBodycheckDaoru",
      "actDeletePdmBodycheckDaoru",
    ]),
    onLoadData() {
      this.actGetPdmBodycheckDaoruListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        TaoCan_Name: this.TaoCan_Name,
        CertNo: this.CertNo,
        YuYueState: this.YuYueState,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
          this.onLoadData2();
        })
        .finally(() => {
          this.$store.commit(
            "PdmBodycheckDaoru/setPdmBodycheckDaoruListLoading",
            false
          );
          //this.PdmBodycheckDaoruListLoading = false;
        });
    },
    onLoadData2() {
      DaoRu.GetDaoRuTaoCanList({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      }).then((res) => {
        if (res.Code == 0) {
          this.DaoRuTaoCan = res.Data;
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onAdd() {
      this.actCreatePdmBodycheckDaoru({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmBodycheckDaoru(obj);
    },
    onDel(val) {
      dlg.confirm("是否确定要删除？").onOk(() => {
        BodycheckDaoru.DelPDM_BodyCheck_DaoRu({
          HospID: this.$store.getters["appUser/hospID"],
          CUser: this.$store.getters["appUser/userID"],
          Types: val,
        }).then((res) => {
          if (res.Code == 0) {
            this.onLoadData();
            this.msg.success(res.Message);
          } else {
            dlg.alert("删除失败，原因:" + res.Message);
          }
        });
      });
    },
    onDelTaoCan(){
      dlg.confirm("是否确定要删除？").onOk(() => {
        DaoRu.DelDaoRuTaoCan({
          HospID: this.$store.getters["appUser/hospID"],
          CUser: this.$store.getters["appUser/userID"],
          TaoCan_Code: this.TaoCanCode,
        }).then((res) => {
          if (res.Code == 0) {
            this.onLoadData();
            this.msg.success(res.Message);
          } else {
            dlg.alert("删除失败，原因:" + res.Message);
          }
        });
      });
    },
    onDelete(e) {
      this.actDeletePdmBodycheckDaoru({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    onImportExcel() {
      this.$refs.ImportExcel.pickFiles();
      this.$refs.ImportExcel.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
    onUploaded(info) {
      console.log("onUploaded==>", info);
      var res = JSON.parse(info.xhr.responseText).ReturnSign;
      console.log("res==>", res);
      if (res.Code == 0) {
        this.onLoadData();
        this.msg.success(res.Message);
      } else {
        dlg.alert(res.Message);
      }
      this.$store.commit(
        "PdmBodycheckDaoru/setPdmBodycheckDaoruListLoading",
        false
      );
    },
    onUploading(info) {
      console.log("onUploading==>", info);
      this.$store.commit(
        "PdmBodycheckDaoru/setPdmBodycheckDaoruListLoading",
        true
      );
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
